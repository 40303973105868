import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hub/hero/hero'
import CardSlider from '../components/hub/cardSlider/cardSlider'

import * as styles from '../components/globals-v2.module.css'
import Icon from '../components/icons'
import * as linkStyle from '../styles/components/links.module.css'
import * as start from '../pages/content-hub/start.module.css'
import { Image } from '../components/Image'
import { isExternalUrl } from '../lib/helpers'

export const query = graphql`
    query SeriesTemplateQuery($id: String!, $seriesTitle: String!) {
      series: sanitySeries(id: { eq: $id }) {
          _id
          _type
          tags {
              title
              slug {
                  current
              }
          }
          title
          seriesTitle
          slug {
              current
          }
          relatedContent {
              ... on SanityAudio {
                  _type
                  title
                  slug {
                      current
                  }
                  series {
                      title
                      slug {
                          current
                      }
                  }
                  featureImage {
                      asset {
                          _id
                          url
                      }
                  }
                  tags {
                      title
                      slug {
                          current
                      }
                  }
                  appleUrl
                  spotifyUrl
                  youTubeUrl
              }
              ... on SanityVideo {
                  _type
                  title
                  slug {
                      current
                  }
                  series {
                      title
                      slug {
                          current
                      }
                  }
                  tags {
                      title
                      slug {
                          current
                      }
                  }
                  featureImage {
                      asset {
                          _id
                          url
                      }
                  }
                  appleUrl
                  spotifyUrl
                  youTubeUrl
              }
              ... on SanityWritten {
                  _id
                  _type
                  publishedAt
                  tags {
                      title
                      slug {
                          current
                      }
                  }
                  title
                  slug {
                      current
                  }
                  featureImage {
                      asset {
                          url
                          _id
                      }
                  }
              }
          }
          featureImage {
              asset {
                  _id
                  url
              }
          }
          appleUrl
          spotifyUrl
          youTubeUrl
          metaTitle
          metaDescription
          metaKeywords
          metaImageFB {
              asset {
                  _id
                  url
              }
          }
          metaImageTW {
              asset {
                  _id
                  url
              }
          }
      }
        
      episodes: allSanityAudio(sort: {order: DESC, fields: publishedAt}, filter: {series: { title:{ eq: $seriesTitle }}}) {
            nodes {
                _id
                _type
                tags {
                    title
                    slug {
                        current
                    }
                }
                title
                slug {
                    current
                }
                series {
                    title
                }
                thumbnail {
                    asset {
                        _id
                        url
                    }
                }
                featureImage {
                    asset {
                        _id
                        url
                    }
                }
                appleUrl
                spotifyUrl
                youTubeUrl
                publishedAt
            }
        }

      videos: allSanityVideo(sort: {order: DESC, fields: publishedAt}, filter: {series: { title:{ eq: $seriesTitle }}}) {
          nodes {
              _id
              _type
              tags {
                  title
                  slug {
                      current
                  }
              }
              title
              slug {
                  current
              }
              series {
                  title
              }
              thumbnail {
                  asset {
                      _id
                      url
                  }
              }
              featureImage {
                  asset {
                      _id
                      url
                  }
              }
              appleUrl
              spotifyUrl
              youTubeUrl
              publishedAt
          }
      }
        
      settings: sanityContentHubSettings {
          podcastsSection {
                _id
                _type
                title
                seriesTitle
                slug {
                    current
                }
                featureImage {
                    asset {
                        _id
                        url
                    }
                }
                appleUrl
                spotifyUrl
                youTubeUrl
          }
      }
        
      social: allSanitySocial (sort: {order: DESC, fields: publishedAt}) {
          nodes {
              url
              title
              platform
              description,
              featureImage: image {
                  asset {
                      _id
                      url
                  }
              }
          }
      }
  }
`

const seriesTemplate = props => {
  const { data } = props
  const [allPodcasts, setAllPodcasts] = useState(null)

  const series = data && data.series
  const podcasts = data.settings.podcastsSection.filter(podcastItem => podcastItem.title !== series.title)

  const social = data.social.nodes

  // useEffect(() => {
  //   const updateViews = async () => {
  //     try {
  //       // eslint-disable-next-line no-undef
  //       await fetch('/api/updateViews/', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({ postId: series._id })
  //       })
  //     } catch (error) {
  //       console.error('Error updating views:', error)
  //     }
  //   }
  //
  //   updateViews()
  // }, [series._id])

  useEffect(() => {
    if (!data.episodes.nodes && !data.videos.nodes) {
      setAllPodcasts(null)
      return
    }

    const allContent = [...data.episodes.nodes, ...data.videos.nodes]
      .filter(node => !node._id.startsWith('drafts.'))
      .sort((a, b) => {
        const dateA = new Date(a.publishedAt)
        const dateB = new Date(b.publishedAt)

        return dateB - dateA
      })

    setAllPodcasts(allContent)
  }, [data.episodes.nodes, data.videos.nodes])

  return (
    <Layout hideContactBar hideAnnouncements logoOverrideUrl='/content-hub/' palette='red'>

      <SEO
        title={series.metaTitle || series.title} description={series.metaDescription} keywords={series.metaKeywords}
        imageFB={series.metaImageFB} imageTW={series.metaImageTW}
      />
      <Container class={styles.container} style={{ paddingBottom: 0 }}>
        <Hero type='audio' record={series} />

        <section className={`${styles.singlePageContainer} ${styles.singlePageAudioContainer}`}>
          <div className={styles.singlePageContent}>
            <div className={styles.singlePageContentTitle}>
              <div className={styles.singlePageContentTitleText}>
                <h2>Latest</h2>
              </div>
            </div>
            <div className={styles.singlePageAudioLatest}>
              {allPodcasts?.length > 0 ? (
                allPodcasts.map((episode, index) => (
                  <div key={index} className={styles.singlePageAudioCardWrapper}>
                    {episode._type !== 'video' ? (
                      <Image imgObj={episode.thumbnail || episode.featureImage} props={{ width: 100, height: 100, alt: episode.title }} />
                    ) : (
                      <Link to={`/content-hub/${episode._type}/${episode.slug?.current}/`}>
                        <Image imgObj={episode.thumbnail || episode.featureImage} props={{ width: 100, height: 100, alt: episode.title }} />
                      </Link>
                    )}
                    <div className={styles.singlePageRelatedCard}>
                      <div>
                        <div className={styles.singlePageRelatedCardSeries}>{episode.series.title}</div>
                        {episode._type !== 'video' ? (<div className={styles.singlePageRelatedCardTitle}>{episode.title}</div>) : <Link to={`/content-hub/${episode._type}/${episode.slug?.current}/`} className={styles.singlePageRelatedCardTitle}>{episode.title}</Link>}
                      </div>
                      {(episode.spotifyUrl || episode.appleUrl || episode.youTubeUrl) && (
                        <div className={`${styles.listenOn} ${styles.icons}`}>Listen on
                          {episode.spotifyUrl && (<a className={styles.spotify} href={episode.spotifyUrl} target='_blank' rel='noreferrer'><Icon symbol='spotify' /></a>)}
                          {episode.appleUrl && (<a className={styles.apple} href={episode.appleUrl} target='_blank' rel='noreferrer'><Icon symbol='apple' /></a>)}
                          {episode.youTubeUrl && (<a className={styles.youtube} href={episode.youTubeUrl} target='_blank' rel='noreferrer'><Icon symbol='youtube' /></a>)}
                        </div>
                      )}
                    </div>
                  </div>
                ))) : (<div>There are no more podcasts matching this series.</div>)}
            </div>
          </div>
          <div className={`${styles.singlePageRelated} ${styles.singlePageRelatedAudio}`}>
            <div className={styles.singlePageRelatedTitle}>More podcasts</div>
            {podcasts !== null && (
              podcasts.map((podcast, index) => (
                <div key={index} className={styles.singlePageRelatedCardWrapper}>
                  <Link to={`/content-hub/${podcast.slug?.current}/`}>
                    <Image imgObj={podcast.featureImage} props={{ width: 188, height: 188, alt: podcast.title }} />
                  </Link>
                  <div className={styles.singlePageRelatedCard}>
                    <Link to={`/content-hub/${podcast.slug?.current}/`} className={styles.singlePageRelatedCardTitle}>{podcast.seriesTitle}</Link>

                    {(podcast.spotifyUrl || podcast.appleUrl || podcast.youTubeUrl) && (
                      <div className={`${styles.listenOn} ${styles.icons}`}>Listen on
                        {podcast.spotifyUrl && !podcast.externalCard && (<a target={isExternalUrl(podcast.spotifyUrl) ? '_blank' : '_self'} className={styles.spotify} href={podcast.spotifyUrl} rel='noreferrer'><Icon symbol='spotify' /></a>)}
                        {podcast.appleUrl && !podcast.externalCard && (<a target={isExternalUrl(podcast.appleUrl) ? '_blank' : '_self'} className={styles.apple} href={podcast.appleUrl} rel='noreferrer'><Icon symbol='apple' /></a>)}
                        {podcast.youTubeUrl && (<a className={styles.youtube} target={isExternalUrl(podcast.youTubeUrl) ? '_blank' : '_self'} href={podcast.youTubeUrl} rel='noreferrer'><Icon symbol='youtube' /></a>)}
                      </div>
                    )}
                  </div>
                </div>
              )))}
          </div>
        </section>
      </Container>

      <Container class={`${styles.container} ${styles.sliderOverflow}`}>
        <section className={styles.socialContainer}>
          <div className={`${start.oneColumn} ${start.separator} ${start.red}`}>
            <CardSlider title='Social' type='social' data={social} settings={{ slidesToShow: 4 }} alternate />
          </div>
        </section>
      </Container>

      <Container class={`${styles.defaultPaddings} ${styles.socialButtonsWrapper}`}>
        <Link to='https://www.youtube.com/@redpoint_ventures' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Youtube
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://www.instagram.com/redpoint/?hl=en' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Insta
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://www.tiktok.com/@redpoint?lang=en' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>TikTok
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://www.linkedin.com/company/redpointventures' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>LinkedIn
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://x.com/redpoint' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>X
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
        <Link to='https://open.spotify.com/show/5WqBqDb4br3LlyVrdqOYYb' target='_blank' className={`${linkStyle.linkArrow} ${linkStyle.white} ${styles.linkArrow}`}>Spotify
          <span>
            <div className={linkStyle.iconCircle} />
            <div className={`${linkStyle.icon} ${styles.icon}`}>
              <Icon symbol='newsArrowIcon' color='currentColor' />
            </div>
          </span>
        </Link>
      </Container>
    </Layout>
  )
}

export default seriesTemplate
